body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
}
 
.App {
  text-align: center;
  padding: 20px;
}
 
.App-header {
  background-color: white;
  padding: 20px;
  color: white;
}
 
.App-logo {
  height: 300px !important;
  margin-bottom: 20px;
}
 
.App-message {
  margin-top: 20px;
}
 
 
 
.Success-box {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffc;
  border: 2px solid #390;
  border-radius: 5px;
  margin-bottom: 20px;
}
 
.Checkbox-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #e0ffe0;
  border: 2px solid #00b300;
  border-radius: 50%;
  margin-right: 10px;
}
 
/*
.Checkbox-circle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 30px;
  height: 30px;
  background-color: #e0ffe0;
  border: 2px solid #00b300;
  border-radius: 50%;
  margin: 0 10px 10px 0;
} */
 
.Checkbox-input {
  display: none;
  margin-left: 20px;
}
 
.Checkbox-icon {
  font-size: 26px;
  align-items: center;
  color: #00b300;
}
 
.Checkbox-label {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: -5px;
  margin-right: 20px;
}
 
.Success-message {
  color: #333;
  font-size: 14px;
}
 
.App-congratulations {
  font-size: 18px;
  color: #333;
}
 
/* Media query for mobile devices */
@media screen and (max-width: 768px) {
  .App-logo {
    height: 160px !important;
    margin-bottom: 10px;
  }
 
  .Checkbox-label {
    font-size: 20px;
  }
 
  .Success-message {
    font-size: 20px;
  }
 
  .App-congratulations {
    font-size: 18px;
  }
}