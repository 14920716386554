.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.logoContainer {
  /* display: flex;
  flex-direction: row;
  background-color: rgb(237, 255, 233);
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2%; */
  display: flex;
  flex-direction: row;
  background-color: rgb(237, 255, 233);
  /* width: 88%; */
  /* padding: 2%; */
  align-items: center;
}
 
 
 
.App-logo {
  height: 160px  !important;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
}
 
 
 
 
 
.header {
  text-align: center;
  margin-bottom: 20px;
}
.form {
  display: block;
  margin-top: 0em;
  unicode-bidi: isolate;
}
 
.form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-group {
display: flex;
flex-wrap: wrap;
/* gap: 2px;  */
margin-bottom: 10px;
}
 
.inline {
display: flex;
align-items: center;
gap: 5px; /* Adjusts space between label and span */
}
 
.underline {
margin-left: 2px; /* Adjusts space between label and text */
}

p {
  margin-bottom: 10px;
}
 
.submit-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  cursor: pointer;
}
 
@media (max-width: 767px) {
  .container {
    max-width: 90%;
  }
  .logoContainer{
    width: 95%;
  }
  .App-logo {
    height: 150px;
  }
  .logoContainer div{
   height: auto !important;
 
  }
  .logoContainer div svg{
   
    width: 80px !important;
    height: 80px !important;
   }
}
 
@media only screen and (max-width: 576px) {
  .container {
    max-width: 100%;
   
  }
  .App-logo {
    height: 120px; /* Further adjustment for extra small screens */
  }
  .hero{
    font-size: 50px;
  }
   .ero {
    font-size: 64px;
}
 
.fincorp {
    font-size: 30px;
}
 
  .header {
    font-size: 1.5em;
  }
  .paragraph {
    text-align: justify;
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 8px 16px;
  }
}
 
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 20px;
  }
  .App-logo {
    height: 200px;
  }
 
  .hero, .ero {
    font-size: 60px;
}
 
.fincorp {
    font-size: 35px;
}
 
.form-group {
    flex-direction: column;
    align-items: flex-start;
}
 
.inline {
    flex-direction: column;
    align-items: flex-start;
}
 
.underline {
    margin-left: 0;
}
 
  .header {
    font-size: 2em;
  }
  .paragraph {
    text-align: justify; /* Align paragraph text */
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 10px 20px;
  }
}
 
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .container {
    max-width: 800px;
    padding: 30px;
  }
  .App-logo {
    height: 250px;
  }
 
  .header {
    font-size: 2.5em;
  }
  .paragraph {
    text-align: justify; /* Align paragraph text */
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 12px 24px;
  }
}
 
/* For 9 inch Tab */
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .container {
    max-width: 1000px;
    padding: 40px;
  }
  .logoContainer {
    width: 100%;
}
 
.App-logo {
  height: 280px;
}
 
.hero {
    font-size: 80px;
}
 
.ero {
    font-size: 100px;
}
 
.fincorp {
    font-size: 50px;
}
 
  .header {
    font-size: 3em;
  }
  .paragraph {
    text-align: justify; /* Align paragraph text */
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 15px 30px;
  }
}
 
/* For desktop */
@media only screen and (min-width: 1201px) {
  .container {
    max-width: 1200px;
    padding: 50px;
  }
 
  .header {
    font-size: 3.5em;
  }
  .paragraph {
    text-align: justify;
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 18px 36px;
  }
}
 
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
 
  .header {
    font-size: 1.5em;
  }
  .paragraph {
    text-align: justify;
    margin-bottom: 10px;
  }
  .submit-button {
    padding: 8px 16px;
  }
}
 
/* for web */
@media (min-width: 601px) and (max-width: 1024px) {
  .container {
    max-width: 600px;
    padding: 15px;
  }
 
  .header {
    font-size: 2em;
  }
  .paragraph {
    text-align: justify;
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 12px 24px;
  }
}
 
@media (min-width: 1025px) {
  .container {
    max-width: 1000px;
    padding: 30px;
  }
 
  .header {
    font-size: 2.5em;
  }
  .paragraph {
    text-align: justify; /* Align paragraph text */
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 15px 30px;
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .container {
    max-width: 600px;
    padding: 15px;
  }
  .logoContainer{
    width: 95%;
  }
  .logoContainer div{
   height: auto !important;
 
  }
  .logoContainer div svg{
   
    width: 80px !important;
    height: 80px !important;
   }
  .hero-container {
 
    display: flex;
    align-items: baseline;
    margin-left: 20px;
  }
 
   .hero {
      font-size: 60px;
      font-weight: 900;
      color: #0b403c;
      line-height: 1;
  }
   
 
  .ero {
    font-size: 80px;
    /* font-size: 120px; */
    font-weight: bold;
    color: #0b403c;
    line-height: 0px;
  }
 
  /* .h {
    font-size: 90px;
    font-weight: bold;
    color: #0b403c;
    line-height: 1;
  } */
 
  .fincorp {
    font-size: 38px;
    font-weight: bold;
    color: #0b403c;
    line-height: 1;
    margin-left: 20px;
  }
 
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  .form {
    display: block;
    margin-top: 0em;
    unicode-bidi: isolate;
  }
  .header {
    font-size: 2em;
  }
  .paragraph {
    text-align: justify;
    margin-bottom: 10px;
  }
 
  .submit-button {
    padding: 12px 24px;
  }
 
} 