.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
 
  .header {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
 
/* .App-logo {
  height: 200px;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
} */
 .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
 
 .full-page-form {
    width: 100%;
     max-width: 600px;
   background: white;
   margin-top: 20px;
    /* padding: 20px; */
 
 }
 .App-logo {
  height: 160px !important;
  margin-bottom: 20px;
  align-items: center;
}
 
.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjusts space between the groups */
    margin-bottom: 10px; /* Adjusts space between form groups */
}
 
.inline {
    display: flex;
    align-items: center;
    gap: 5px; /* Adjusts space between label and span */
}
 
.underline {
    margin-left: 2px; /* Adjusts space between label and text */
}
 
 
 
 
 
 
 
 
  p {
    margin-bottom: 10px;
  }
 
  .submit-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    cursor: pointer;
  }
 
  @media (max-width: 768px) {
    .container {
      max-width: 90%;
    }
  }
 
  @media only screen and (max-width: 576px) {
    .container {
      max-width: 100%;
      padding: 10px;
    }
 
    .header {
      font-size: 1.5em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 8px 16px;
    }
  }
 
  @media only screen and (min-width: 577px) and (max-width: 768px) {
    .container {
      max-width: 100%;
      padding: 20px;
    }
 
    .header {
      font-size: 2em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 10px 20px;
    }
  }
 
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .container {
      max-width: 800px;
      padding: 30px;
    }
 
    .header {
      font-size: 2.5em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 12px 24px;
    }
  }
 
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .container {
      max-width: 1000px;
      padding: 40px;
    }
 
    .header {
      font-size: 3em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 15px 30px;
    }
  }
 
  @media only screen and (min-width: 1201px) {
    .container {
      max-width: 1200px;
      padding: 50px;
    }
 
    .header {
      font-size: 3.5em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 18px 36px;
    }
  }
 
  @media (max-width: 600px) {
    .container {
      padding: 10px;
    }
 
    .header {
      font-size: 1.5em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
    .submit-button {
      padding: 8px 16px; /* Corrected the slash here */
    }
  }
 
  @media (min-width: 601px) and (max-width: 1024px) {
    .container {
      max-width: 600px;
      padding: 15px;
    }
 
    .header {
      font-size: 2em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 12px 24px;
    }
  }
 
  @media (min-width: 1025px) {
    .container {
      max-width: 1000px;
      padding: 30px;
    }
 
    .header {
      font-size: 2.5em;
    }
    .paragraph {
      text-align: justify; /* Align paragraph text */
      margin-bottom: 10px;
    }
 
    .submit-button {
      padding: 15px 30px;
    }
  }
 